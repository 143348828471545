import { Middleware } from '@nuxt/types'

const routes : Middleware = ({ app }) => {
  app.router?.beforeResolve((to, from, next) => {
    document.documentElement.classList.remove('overflow-hidden')
    next()
  })
}

export default routes
