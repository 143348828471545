import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import IOption from '../shared/general/interfaces/IOption'
import { caasLocale, reverseLocaleReplacer } from '../shared/general/services/LanguageService'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Locale extends VuexModule {
  locales : {
    urlLang : string
    locale : string
    fsxaLocale : string
    caasLocale : string
  }[] = []

  @VuexMutation
  set (locales : IOption[]) : void {
    if (!locales.length) return

    this.locales = locales.map((locale) => ({
      urlLang: new Intl.Locale(`${reverseLocaleReplacer(`${locale.value}`)}`.replace('_', '-'))?.language,
      locale: new Intl.Locale(`${locale.value}`.replace('_', '-'))?.baseName,
      fsxaLocale: caasLocale(`${locale.value}`),
      caasLocale: caasLocale(`${locale.value}`),
    }))
  }

  get fsxaLocaleFromLang () : Function {
    return (lang : string) : string => this.locales
      .filter((locale) => locale.urlLang.toLowerCase() === lang.toLowerCase())?.[0]?.fsxaLocale || ''
  }
}
