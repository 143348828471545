import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class LocationOnlineAppointment extends VuexModule {
  link : string = ''

  @VuexMutation
  set (link : string) : void {
    this.link = link
  }
}
