import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

export interface ISocialSharingData {
  displaySocialShareBar : boolean
  url ?: string // URL to share.
  title ?: string // Sharing title (if available).
  description ?: string // Sharing description (if available).
  quote ?: string // Facebook quote (Facebook only).
  hashtags ?: string // A list of comma-separated hashtags (Twitter and Facebook).
  twitterUser ?: string // Twitter user (Twitter only).
  media ?: string // Url to a media (Pinterest, VK, Weibo, and Wordpress).
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class SocialSharing extends VuexModule {
  displaySocialShareBar = false

  url = ''

  title = ''

  description = ''

  quote = ''

  hashtags = ''

  twitterUser = ''

  media = ''

  @VuexMutation
  setState (state : ISocialSharingData) {
    const nullSafe = <T> (val ?: T | null) => (val || '')
    this.displaySocialShareBar = state.displaySocialShareBar
    this.url = nullSafe(state.url)
    this.title = nullSafe(state.title)
    this.description = nullSafe(state.description)
    this.quote = nullSafe(state.quote)
    this.hashtags = nullSafe(state.hashtags)
    this.twitterUser = nullSafe(state.twitterUser)
    this.media = nullSafe(state.media)
  }
}
