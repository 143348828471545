import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ModalContent extends VuexModule {
  isOpen = false

  component : string | null = null

  props : any = null

  size : string = 'small'

  isFilterOpen = false

  isShowResultsClicked = false

  isHeaderClicked = false

  @VuexMutation
  setContent ({ component, props } : { component : string, props : any }) {
    this.component = component
    this.props = props
  }

  @VuexMutation
  setOpen (isOpen : boolean) {
    this.isOpen = isOpen
  }

  @VuexMutation
  setFilterOpen (isFilterOpen : boolean) {
    this.isFilterOpen = isFilterOpen
  }

  @VuexMutation
  setIsShowResultsClicked (isShowResultsClicked : boolean) {
    this.isShowResultsClicked = isShowResultsClicked
  }

  @VuexMutation
  setIsIsHeaderClicked (isHeaderClicked : boolean) {
    this.isHeaderClicked = isHeaderClicked
  }

  @VuexMutation
  reset () {
    this.isOpen = false
    this.component = null
    this.props = null
  }
}
