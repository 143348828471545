import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Navigation extends VuexModule {
  path : string[] = []

  @VuexMutation
  set (path : string[]) {
    this.path = [...path]
  }
}
