import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import tailwindConfig from '../tailwind.config'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Viewport extends VuexModule {
  mobile : boolean = false

  medium : boolean = false

  @VuexMutation
  set (windowWidth : number) {
    this.mobile = windowWidth < +tailwindConfig.theme.screens.sm.replace('px', '')
    this.medium = windowWidth < +tailwindConfig.theme.screens.md.replace('px', '')
  }

  get isMobile () {
    return this.mobile
  }

  get isMd () {
    return this.medium
  }
}
