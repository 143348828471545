import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import { IMessage } from '../shared/general/interfaces/IMessage'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class MessageBar extends VuexModule {
  message : IMessage = {
    text: '',
    color: 'green',
  }

  @VuexMutation
  set (message : IMessage) {
    this.message = message
  }

  @VuexMutation
  reset () {
    this.message = {
      text: '',
      color: 'green',
    }
  }
}
