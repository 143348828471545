import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Sidebar extends VuexModule {
  open : boolean = false

  @VuexMutation
  set (open : boolean) {
    this.open = open
  }
}
