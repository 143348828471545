import Vue from 'vue'

let keydownCallback

Vue.directive('keydown-esc', {
  bind (el, binding) {
    keydownCallback = (event) => {
      if (event.key === 'Escape') {
        binding.value?.()
      }
    }
    document.addEventListener('keydown', keydownCallback)
  },
  unbind () {
    if (keydownCallback) {
      document.removeEventListener('keydown', keydownCallback)
      keydownCallback = undefined
    }
  },
})
