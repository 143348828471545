import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import { caasLocale } from '../shared/general/services/LanguageService'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Locale extends VuexModule {
  fsxaLocale : string = caasLocale()

  // Language tag (BCP 47) for dates and localeCompare()
  locale : string = this.fsxaLocale.replaceAll('_', '-')

  @VuexMutation
  set (fsxaLocale : string) {
    if (fsxaLocale?.length !== 5) return

    this.fsxaLocale = caasLocale(fsxaLocale)
    this.locale = fsxaLocale.replaceAll('_', '-')
  }
}
