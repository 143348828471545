/* eslint-disable max-len */
const plugin = require('tailwindcss/plugin')
const containerQueries = require('@tailwindcss/container-queries')

module.exports = {
  content: [
    './pages/**/*.vue',
    './pages/**/*.tsx',
    './pages/**/*.jsx',
    './components/**/*.vue',
    './components/**/*.tsx',
    './components/**/*.jsx',
    './assets/**/*.css',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}',
  ],
  theme: {
    extend: {
      boxShadow: {
        base: '0px 2px 8px rgba(30, 30, 30, 0.12)',
        md: '0px 4px 6px rgba(30, 30, 30, 0.04), 0px 8px 16px rgba(30, 30, 30, 0.08)',
        lg: '0px 8px 12px -4px rgba(30, 30, 30, 0.04), 0px 20px 24px -4px rgba(30, 30, 30, 0.1)',
        xl: '0px 24px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-up': '0px -4px 6px rgba(30, 30, 30, 0.04), 0px -8px 16px rgba(30, 30, 30, 0.08)',
        'lg-up': '0px -8px 12px -4px rgba(30, 30, 30, 0.04), 0px -20px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-up': '0px -24px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-right': '4px 0px 6px rgba(30, 30, 30, 0.04), 8px 0px 16px rgba(30, 30, 30, 0.08)',
        'lg-right': '8px 0px 12px -4px rgba(30, 30, 30, 0.04), 20px 0px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-right': '24px 0px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-left': '-4px 0px 6px rgba(30, 30, 30, 0.04), -8px 0px 16px rgba(30, 30, 30, 0.08)',
        'lg-left': '-8px 0px 12px -4px rgba(30, 30, 30, 0.04), -20px 0px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-left': '-24px 0px 48px -12px rgba(30, 30, 30, 0.24)',

        'base-inset': 'inset 0px 2px 8px rgba(30, 30, 30, 0.12)',
        'md-inset': 'inset 0px 4px 6px rgba(30, 30, 30, 0.04), inset 0px 8px 16px rgba(30, 30, 30, 0.08)',
        'lg-inset': 'inset 0px 8px 12px -4px rgba(30, 30, 30, 0.04), inset 0px 20px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-inset': 'inset 0px 24px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-up-inset': 'inset 0px -4px 6px rgba(30, 30, 30, 0.04), inset 0px -8px 16px rgba(30, 30, 30, 0.08)',
        'lg-up-inset': 'inset 0px -8px 12px -4px rgba(30, 30, 30, 0.04), inset 0px -20px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-up-inset': 'inset 0px -24px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-right-inset': 'inset 4px 0px 6px rgba(30, 30, 30, 0.04), inset 8px 0px 16px rgba(30, 30, 30, 0.08)',
        'lg-right-inset': 'inset 8px 0px 12px -4px rgba(30, 30, 30, 0.04), inset 20px 0px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-right-inset': 'inset 24px 0px 48px -12px rgba(30, 30, 30, 0.24)',
        'md-left-inset': 'inset -4px 0px 6px rgba(30, 30, 30, 0.04), inset -8px 0px 16px rgba(30, 30, 30, 0.08)',
        'lg-left-inset': 'inset -8px 0px 12px -4px rgba(30, 30, 30, 0.04), inset -20px 0px 24px -4px rgba(30, 30, 30, 0.1)',
        'xl-left-inset': 'inset -24px 0px 48px -12px rgba(30, 30, 30, 0.24)',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '3/4': '3 / 4',
        '9/16': '9 / 16',
      },
      gridRow: {
        1: '1',
      },
      containers: {
        xs: '0px',
        sm: '600px',
        md: '900px',
        lg: '1284px',
        xl: '1320px',
        xxl: '1920px',
      },
      height: {
        screen: ['100vh /* fallback value */', '100dvh'],
      },
    },
    colors: {
      current: 'currentColor',
      transparent: 'transparent',
      white: '#ffffff',
      black: '#1E1E1E',
      green: '#008143',
      lightgreen: '#64B32C',
      pink: '#C7066E',
      lightblue: '#005EE8',
      darkblue: '#002E55',
      orange: {
        DEFAULT: '#EF7D02',
        50: '#FEF6ED',
        100: '#FBDEBF',
        200: '#F8C48C',
        300: '#F4A34C',
        400: '#EF7D02',
        500: '#AF5B00',
        600: '#944D00',
        700: '#733C00',
        800: '#542B00',
        900: '#241300',
      },
      yellow: {
        DEFAULT: '#FFCC00',
        50: '#FFF9E0',
        100: '#FFE066',
        200: '#FFCC00',
        300: '#E6B800',
        400: '#BF9900',
        500: '#876C00',
        600: '#735C00',
        700: '#594700',
        800: '#403300',
        900: '#1A1400',
      },
      brightgreen: {
        DEFAULT: '#B0FD78',
        50: '#EFFFE4',
        100: '#C8FEA1',
        200: '#B0FD78',
        300: '#9EE46C',
        400: '#7BB154',
        500: '#527638',
        600: '#466530',
        700: '#354C24',
        800: '#233318',
        900: '#12190C',
      },
      darkgreen: {
        DEFAULT: '#006B52',
        50: '#F2F8F6',
        100: '#CCE1DC',
        200: '#B3D3CB',
        300: '#80B5A9',
        400: '#4D9786',
        500: '#006B52',
        600: '#01604A',
        700: '#015642',
        800: '#014031',
        900: '#002019',
      },
      gray: {
        DEFAULT: '#696969',
        50: '#F8F8F8',
        100: '#EDEDED',
        200: '#D2D2D2',
        300: '#B4B4B4',
        400: '#9D9D9D',
        500: '#696969',
        600: '#595959',
        700: '#444444',
        800: '#353535',
        900: '#1F1F1F',
      },
      red: {
        DEFAULT: '#D10000',
        50: '#FDF2F2',
        100: '#F8D9D9',
        200: '#F3BFBF',
        300: '#ED9999',
        400: '#E36666',
        500: '#D10000',
        600: '#BC0000',
        700: '#9D0000',
        800: '#7D0000',
        900: '#3F0000',
      },
    },
    fontSize: {
      '6xl': ['3rem', '4rem'], // Stage (48px, ??px)
      '5xl': ['2.5rem', '3rem'], // H1 (40px, 48px)
      '4xl': ['2rem', '2.5rem'], // (32px, 40px)
      '3xl': ['1.75rem', '2.25rem'], // (28px, 36px)
      '2xl': ['1.5rem', '2rem'], // H2 (24px, 32px)
      xl: ['1.25rem', '1.75rem'], // H3 (20px, 28px)
      lg: ['1.125rem', '1.5rem'], // (18px, 24px)
      base: ['1rem', '1.5rem'], // (16px, 24px)
      sm: ['0.875rem', '1.25rem'], // (14px, 20px)
      xs: ['0.75rem', '1rem'], // (12px, 16px)
      xxs: ['0.625rem', '0.75rem'], // (10px, 12px)
    },
    screens: {
      xs: '0px',
      // => @media (max-width: 599px) { ... }

      sm: '600px',
      // => @media (min-width: 600px) { ... }

      md: '900px',
      // => @media (min-width: 900px) { ... }

      lg: '1284px',
      // => @media (min-width: 1284px) { ... }

      xl: '1320px',
      // => @media (min-width: 1320px) { ... }

      xxl: '1920px',
      // => @media (min-width: 1920px) { ... }
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
      square: 'square',
    },
    fontFamily: {
      main: ['Noto Sans', 'sans-serif'],
    },
    letterSpacing: {
      default: '0.0125rem',
    },
  },
  variants: {},
  plugins: [
    containerQueries,
    plugin(({ addUtilities }) => {
      addUtilities({
        '.break-hyphens': {
          hyphens: 'auto',
          'word-break': 'break-word',
          'overflow-wrap': 'break-word',
        },
        '.place-self-end': {
          'place-self': 'end',
        },
        '.place-self-start': {
          'place-self': 'start',
        },
      })
    }),
  ],
}
