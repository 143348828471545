import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import IBreadcrumbItem from '../shared/general/interfaces/IBreadcrumbItem'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Breadcrumbs extends VuexModule {
  items : IBreadcrumbItem[] = []

  @VuexMutation
  set (newCrumbs : IBreadcrumbItem[]) {
    this.items = [...newCrumbs]
  }

  get crumbs () {
    return this.items || []
  }
}
