import { Module, VuexAction, VuexModule } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class SmartSearch extends VuexModule {
  @VuexAction
  triggerSearchResultSearch () : void {
    // This is intentional
  }
}
