import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

interface IVendor {
  key : string
  value : boolean
}

export const getPrivacySettings = () : Record<string, string> => {
  const privacySettings = localStorage.getItem('privacySettings')
  return privacySettings ? JSON.parse(privacySettings) : {}
}

export const getPrivacySettingsValue = (key : string) : boolean => {
  const privacySettings = getPrivacySettings()
  return privacySettings[key] === 'true'
}

export const getPrivacySettingsKeys = () : string[] => Object.keys(getPrivacySettings())

export const setPrivacySettings = (key : string, value : boolean) : void => {
  const privacySettings = getPrivacySettings()
  privacySettings[key] = value.toString()
  localStorage.setItem('privacySettings', JSON.stringify(privacySettings))
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class PrivacySettings extends VuexModule {
  settings : Record<string, boolean> = {}

  @VuexMutation
  set (vendor : IVendor) {
    this.settings = { ...this.settings, [vendor.key]: vendor.value }
    setPrivacySettings(vendor.key, vendor.value)
  }

  @VuexMutation
  setStateAll (vendors : IVendor[]) {
    vendors.forEach((vendor : IVendor) => {
      this.settings = { ...this.settings, [vendor.key]: vendor.value }
    })
  }

  @VuexMutation
  reset () {
    this.settings = {}
  }
}
