import { RichTextElement } from 'fsxa-api'
import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Snackbar extends VuexModule {
  text : string | RichTextElement[] = ''

  @VuexMutation
  set (text : string | RichTextElement[]) {
    this.text = text
  }
}
