import {
  Module, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class LocationInformation extends VuexModule {
  locationImage : string = ''

  specialServicesList : string[] = []

  @VuexMutation
  setLocationImage (image ?: string) {
    if (image?.length) this.locationImage = image
  }

  @VuexMutation
  setSpecialServicesList (list ?: string[]) {
    if (list?.length) this.specialServicesList = [...list]
  }
}
