// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./noto-sans/NotoSans-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./noto-sans/NotoSans-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./noto-sans/NotoSans-Italic.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./noto-sans/NotoSans-Italic.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./noto-sans/NotoSans-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./noto-sans/NotoSans-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./noto-sans/NotoSans-SemiBoldItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./noto-sans/NotoSans-SemiBoldItalic.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./noto-sans/NotoSans-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./noto-sans/NotoSans-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./noto-sans/NotoSans-BoldItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./noto-sans/NotoSans-BoldItalic.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:block;font-family:\"Noto Sans\";font-style:normal;font-weight:400;src:local(\"Noto Sans Regular\"),local(\"NotoSans-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-display:block;font-family:\"Noto Sans\";font-style:italic;font-weight:400;src:local(\"Noto Sans Italic\"),local(\"NotoSans-Italic\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-display:block;font-family:\"Noto Sans\";font-style:normal;font-weight:600;src:local(\"Noto Sans SemiBold\"),local(\"NotoSans-SemiBold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-display:block;font-family:\"Noto Sans\";font-style:italic;font-weight:600;src:local(\"Noto Sans SemiBold Italic\"),local(\"NotoSans-SemiBoldItalic\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\")}@font-face{font-display:block;font-family:\"Noto Sans\";font-style:normal;font-weight:700;src:local(\"Noto Sans Bold\"),local(\"NotoSans-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\")}@font-face{font-display:block;font-family:\"Noto Sans\";font-style:italic;font-weight:700;src:local(\"Noto Sans Bold Italic\"),local(\"NotoSans-BoldItalic\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
