import { Store } from 'vuex'

export default class StoreSingleton {
  private static store : Store<any>

  public static init (store : Store<any>) : void {
    this.store = store
  }

  public static get instance () : Store<any> {
    return this.store
  }
}
