import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class PageHeader extends VuexModule {
  homepage = '/'

  contactPage = ''

  siteIdentifier = ''

  @VuexMutation
  setHomepage (homepage ?: string) {
    if (homepage) this.homepage = homepage
  }

  @VuexMutation
  setContactPage (contactPage ?: string) {
    if (contactPage) this.contactPage = contactPage
  }

  @VuexMutation
  setSiteIdentifier (siteIdentifier ?: string) {
    if (siteIdentifier) this.siteIdentifier = siteIdentifier
  }
}
