import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import { IAnchorValue } from '../shared/fsxa/interfaces/IAnchorLink'
import { formatToCleanParam } from '../shared/general/services/AnchorLinkService'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class AnchorLinks extends VuexModule {
  anchorLinks : IAnchorValue[] = []

  @VuexMutation
  setAnchorLinks (anchorLinks ?: IAnchorValue[]) {
    if (anchorLinks) this.anchorLinks = anchorLinks
  }

  get getAnchorNameForSection () : Function {
    return (sectionId : string) : string | undefined => {
      const res = this.anchorLinks.find((anchor) => anchor.identifier === sectionId)
      if (res?.text) {
        return formatToCleanParam(res.text)
      }
      return undefined
    }
  }
}
