import {
  error, info, verbose, warn,
} from './LogKey'

const logLevel : number = (process.env.LOG_LEVEL && !Number.isNaN(+process.env.LOG_LEVEL)) ? +process.env.LOG_LEVEL : 0

// eslint-disable-next-line import/prefer-default-export
export class Logger {
  static #log (fn : string, type : string, ...messages : unknown[]) {
    // eslint-disable-next-line no-console
    console[fn](type, ...messages)
  }

  static verbose (...messages : unknown[]) {
    if (logLevel > 0) return
    Logger.#log('log', verbose, ...messages)
  }

  static info (...messages : unknown[]) {
    if (logLevel > 1) return
    Logger.#log('info', info, ...messages)
  }

  static warn (...messages : unknown[]) {
    if (logLevel > 2) return
    Logger.#log('warn', warn, ...messages)
  }

  static error (...messages : unknown[]) {
    Logger.#log('error', error, ...messages)
  }
}
