import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class SidebarContent extends VuexModule {
  headline : string = ''

  component : string | undefined = undefined

  props : any = undefined

  @VuexMutation
  setHeadline (headline : string) {
    this.headline = headline
  }

  @VuexMutation
  setContent ({ component, props } : { component : string, props : any }) {
    this.component = component
    this.props = props
  }

  @VuexMutation
  reset () {
    this.component = undefined
    this.props = undefined
  }
}
