import {
  Module, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'
import Vue from 'vue'
import { IToolBarElement } from '../shared/general/interfaces/IToolbarElement'
import { ILocationTypeFilterData } from '../shared/fsxa/interfaces/ILocationData'
import { TLocationSearchView } from '../shared/fsxa/types/TView'

type TLocationSidebarProps = {
  showLocationSidebarElement : boolean
  locationSidebarView : TLocationSearchView | undefined
  locationSidebarType : ILocationTypeFilterData | undefined
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ToolbarElements extends VuexModule {
  fixedElements : IToolBarElement[] = []

  elements : IToolBarElement[] = []

  showLocationSidebarElement : boolean = false

  onlineAppointmentLink : string = ''

  locationSidebarType : ILocationTypeFilterData | undefined = undefined

  locationSidebarView : TLocationSearchView | undefined = undefined

  @VuexMutation
  setFixed (elements : IToolBarElement[]) {
    this.fixedElements = [...elements]
  }

  @VuexMutation
  add (element : IToolBarElement) : void {
    const foundElement : IToolBarElement | undefined = this.elements.find((toolbarElement : IToolBarElement) => toolbarElement.text === element.text)
    if (foundElement) {
      Vue.set(this.elements, this.elements.indexOf(foundElement), element)
    } else {
      this.elements.push(element)
    }
  }

  @VuexMutation
  setOnlineAppointmentLink (link : string) {
    this.onlineAppointmentLink = link
  }

  @VuexMutation
  setLocationSidebarProps ({ showLocationSidebarElement, locationSidebarView, locationSidebarType } : TLocationSidebarProps) : void {
    this.showLocationSidebarElement = showLocationSidebarElement
    this.locationSidebarView = locationSidebarView
    this.locationSidebarType = locationSidebarType
  }

  @VuexMutation
  reset () : void {
    this.elements = []
    this.fixedElements = []
  }
}
