import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Tracking extends VuexModule {
  parameters : Record<string, string> = {}

  @VuexMutation
  set (parameters : Record<string, string>) {
    this.parameters = parameters
  }
}
