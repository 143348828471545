import { DataEntry } from 'fsxa-api'
import { ILang } from '../interfaces/ILang'

export const caasLocale = (locale : string = process.env.FSXA_LOCALE || 'en_US') : string => {
  if (locale.length !== 5) return locale // do not modify in this case
  return `${locale.substring(0, 2).toLowerCase()}_${locale.substring(3).toUpperCase()}` // xx_XX
}

export const localeReplacer = (_locale ?: string) : string => {
  let locale : string = _locale || process.env.FSXA_LOCALE || ''
  if (locale.length !== 5) return ''

  const localeReplacement : Record<string, Record<string, string>> = JSON.parse(process.env.FSXA_LOCALE_REPLACEMENT || '{}')
  const upperCaseLocale = locale.toUpperCase()

  locale = localeReplacement[upperCaseLocale]
    ? `${localeReplacement[upperCaseLocale]?.language}_${localeReplacement[upperCaseLocale]?.country}`
    : locale

  return locale
}

export const reverseLocaleReplacer = (locale : string) : string => {
  if (locale.length !== 5) return locale

  const localeReplacement : Record<string, Record<string, string>> = JSON.parse(process.env.FSXA_LOCALE_REPLACEMENT || '{}')
  const locales = Object.entries(localeReplacement).reduce((result, [key, value]) => ({
    ...result,
    [`${value.language.toUpperCase()}_${value.country}`]: key,
  }), {})

  return locales[locale.toUpperCase()] || locale
}

export const matchUrlLangToProjectLanguages = (alpha2 : string, languages : Record<string, DataEntry>) : ILang | undefined => {
  const language = languages?.filter(
    (lang : ILang) => {
      const cmsLang = new Intl.Locale(lang.key?.replace('_', '-'))?.language
      return cmsLang.toLowerCase() === alpha2.toLowerCase()
    },
  )?.[0]
  return language
}
