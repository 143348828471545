import {
  Module, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class PageRefMapping extends VuexModule {
  mapping : Record<string, string> | null = null

  @VuexMutation
  set (mapping ?: Record<string, string>) : void {
    if (mapping) this.mapping = mapping
  }

  get get () : Record<string, string> {
    return this.mapping || {}
  }
}
