import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Page extends VuexModule {
  useLocationReference : boolean = false

  locationType : string = ''

  pageType : string = ''

  isNavigationOpen : boolean = false

  maintenanceBarText : string | RichTextElement[] = ''

  maintenanceBarVisible : boolean = false

  @VuexMutation
  setUseLocationReference (value : boolean) {
    this.useLocationReference = value
  }

  @VuexMutation
  setLocationType (locationType : string) : void {
    this.locationType = locationType
  }

  @VuexMutation
  setPageType (pageType : string) : void {
    this.pageType = pageType
  }

  @VuexMutation
  setIsNavigationOpen (value : boolean) {
    this.isNavigationOpen = value
  }

  @VuexMutation
  setMaintenanceBar ({ visible, text } : { visible : boolean, text : string | RichTextElement[] }) {
    this.maintenanceBarVisible = visible
    this.maintenanceBarText = text
  }

  @VuexMutation
  resetMaintenanceBar () {
    this.maintenanceBarVisible = false
    this.maintenanceBarText = ''
  }
}
